import type { FC } from "react";
import React from "react";
import Translate from "src/ui/components/Translate/Translate";
import ErrorBox from "../StyledComponents/ErrorBox";
import type { TranslationKey } from "src/types/translationKey";

const FormError: FC<{
  code?: string;
  msg?: TranslationKey;
  spaceAfter?: boolean;
}> = (props) => {
  const { msg, code, spaceAfter } = props;

  return (
    <>
      {code && (
        <>
          <ErrorBox data-severity="error">
            {msg ? (
              <Translate msg={msg} variables={{ context: code }} />
            ) : (
              <Translate msg={"error_code"} variables={{ context: code }} />
            )}
          </ErrorBox>
          <nine-spacer s={spaceAfter ? "md" : "sm"}></nine-spacer>
        </>
      )}
    </>
  );
};

export default FormError;
